export const footerKeiKei = [
    {
        name: { tr: 'Biz Kimiz', en: 'About Us' },
        slug: {
            en: '/about-us',
            tr: '/biz-kimiz',
        },
    },
    {
        name: { tr: 'Sıkça Sorulan Sorular', en: 'FAQ' },
        slug: {
            en: '/faq',
            tr: '/sikca-sorulan-sorular',
        },
    },
    {
        name: { tr: 'İletişim', en: 'Contact' },
        slug: {
            en: '/contact',
            tr: '/iletisim',
        },
    },
    /* {
        name: { tr: 'Kolay İade', en: 'Easy Return' },
        slug: { en: '/easy-return', tr: '/kolay-iade' },
    }, */
];
export const footerExplore = [
    {
        name: { tr: 'Tüm Kategoriler', en: 'All Categories' },
        slug: {
            en: '/all-categories',
            tr: '/tum-kategoriler',
        },
    },
    {
        name: { tr: 'Kampanyalar', en: 'Campaigns' },
        slug: {
            en: '/sale',
            tr: '/indirim',
        },
    },
    {
        name: { tr: 'İndirim', en: 'Sale' },
        slug: {
            en: '/sale',
            tr: '/indirim',
        },
    },
];
export const footerFollow = [
    {
        name: { tr: 'Facebook', en: 'Facebook' },
        slug: {
            en: 'https://www.facebook.com/keikeicom',
            tr: 'https://www.facebook.com/keikeicom',
        },
    },
    {
        name: { tr: 'Instagram', en: 'Instagram' },
        slug: {
            en: 'https://www.instagram.com/keikei',
            tr: 'https://www.instagram.com/keikei',
        },
    },
    {
        name: { tr: 'Youtube', en: 'Youtube' },
        slug: {
            en:
                'https://www.youtube.com/channel/UC07H4sscaPaPyqckKKqz-1g/featured',
            tr:
                'https://www.youtube.com/channel/UC07H4sscaPaPyqckKKqz-1g/featured',
        },
    },
];

export const footerHelp = [
    {
        name: { tr: 'KVKK', en: 'GDPR' },
        slug: {
            en: '/privacy-policy',
            tr: '/aydinlatma-metni',
        },
    },
    {
        name: { tr: 'Haklar ve Sözleşmeler', en: 'Rights and Assignments' },
        slug: {
            en: '/membership-info',
            tr: '/uyelik-on-bilgilendirme',
        },
    },
    {
        name: { tr: 'Şikayet , öneri ve görüş', en: 'Complaint, suggestion, and feedback' },
        slug: {
            en: '/feedback',
            tr: '/geri-bildirim',
        },
    },
    {
        name: { tr: 'İade Koşulları', en: 'Return Conditions' },
        slug: {
            en: '/change-and-return',
            tr: '/degisim-ve-iade',
        },
    },
];
